.termsAndConditionsSettingForm {
  display: grid;
  gap: 16px;
}

.legalInfo {
  color: var(--color-grayscale-gray);
  font-size: 12px;
  line-height: 1.5;

  a {
    color: var(--color-primary-default);
    text-decoration: none;

    &:focus-visible {
      outline: 4px solid hsl(var(--color-primary-default-hsl) 0.5);
    }
  }
}
