.buttonWrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  word-break: break-word;

  @media (--portrait-tablet) {
    max-width: 420px;
  }

  &[data-variant='codeLogin'] {
    order: 1;
  }

  button {
    width: 100%;
  }
}

.dimmedCopy {
  color: var(--color-grayscale-gray);
  margin-right: 3px;
}
