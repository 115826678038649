.title {
  color: var(--color-grayscale-very-gray);
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 8px;
  font-family: var(--font-work-sans);
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  word-break: break-word;

  @media (--portrait-tablet) {
    font-size: 24px;
  }
}

.subtitle {
  color: var(--color-grayscale-very-gray);
  font-family: var(--font-open-sans);
  font-size: 14px;
  font-weight: 400;

  strong {
    font-weight: 700;
  }
}
